
export class WorkType {

	id: number;
	typeCode: string;
	description: string;
	officeCode: string;
	complexityCode: string;
	complexity: number;
	price: number;
	isFromPool: string;
	color: string;
	isSummable: string;
	
	constructor(
	){}
}

