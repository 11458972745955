import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'app-wo-suspended',
    templateUrl: './wo-suspended.component.html',
    styleUrls: ['./wo-suspended.component.css']
})
export class WoSuspendedComponent {

}
