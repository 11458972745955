import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wo-cancelled',
  templateUrl: './wo-cancelled.component.html',
  styleUrls: ['./wo-cancelled.component.css']
})
export class WoCancelledComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
