import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wo-trashed',
  templateUrl: './wo-trashed.component.html',
  styleUrls: ['./wo-trashed.component.css']
})
export class WoTrashedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
